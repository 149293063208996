import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Form, Row, Col, Button } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYelp, faGoogle } from '@fortawesome/free-brands-svg-icons'

const IndexPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    {/* <Container className="d-lg-flex heroWidth">
        <div lg={6} className="">
          <h1></h1>
          
        </div>
        <div className="">
          <StaticImage
            src="../images/child-white-shirt.jpg"
            loading="eager"
            width={350}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Child in white shirt talking into a microphone"
          />
        </div>
    </Container> */}
    <div className="masthead mb-5">
      <Container className="singleCol text-center">
        <h1>Contact Us</h1>
      </Container>
    </div>
    <Container className="singleCol">
    <p>
          We'd love to hear from you. Simply fill out the form below and we will get back to you as soon as we can.
        </p>
        <p>
        We do not accept insurance. We can provide you with codes for reimbursement. A 24-hour notice of cancellation is required for all scheduled appointments, otherwise the full fee will be charged.
        </p>
        <p>You can also reach us via phone at (415) 505-7212 or at <a href="mailto:kris@vibespeech.com">kris@vibespeech.com</a>.</p>
        <hr />
        <Form 
                        name="contact"
                        method="post"
                        // action="/contact-success/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit="submit"
                    >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label for="firstName">First Name</Form.Label>
                                    <Form.Control 
                                        type="text" name="first-name" id="firstName"
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label for="lastName">Last Name</Form.Label>
                                    <Form.Control 
                                       type="text" name="last-name"
                                       id="lastName"
                                     />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label for="email">Email address</Form.Label>
                              <Form.Control 
                                  required
                                  type="email" 
                                  name="email" 
                                  id="email"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                          <Form.Group>
                            <Form.Label for="phone">Phone</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                name="phone" 
                                id="phone"
                            />
                        </Form.Group>
                          </Col>
                        </Row>
                            
                        

                        
                        
                        <Form.Group>
                            <Form.Label for="help">How can we help you?</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="message"
                                rows="3"
                                id="help"
                             />
                        </Form.Group>
                        <Button type="submit" variant="outline-dark" className="mt-1">Submit</Button>
                        </Form>
                        <hr />
                        <div className="d-flex justify-content-center">
        <a href="https://g.page/r/CcBEiRRzXAhwEAI/review" className="btn btn-outline-primary">
        <FontAwesomeIcon icon={faGoogle} size="lg" className="me-1" /> Leave a Review on Google
        </a>
        <span className="px-2"></span>
        <a href="https://www.yelp.com/biz/vibe-speech-therapy-mill-valley-2" className="btn btn-outline-primary">
        <FontAwesomeIcon icon={faYelp} size="lg" className="me-1" /> Leave a Review on Yelp
        </a>
      </div>
                        
                        

                        
    </Container>
    


  </Layout>
)

export default IndexPage
